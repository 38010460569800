import SimpleButton from 'src/components/SimpleButton'
import { Dialog } from 'src/components/Dialog'
import { Icon } from 'src/components/Icon'
import { InputDateTime } from 'src/components/InputDateTime'

export const ScheduleModal = ({
  closeModal,
  event,
  isLiveEvent,
  isOpen,
  onScheduleParty,
  startTime,
  setStartTime,
}) => {
  return (
    <Styles.Dialog hideScrollbar isOpen={isOpen} onClose={closeModal}>
      <Styles.Dismiss noBorder onClose={closeModal} />
      <Styles.Body>
        <Styles.Header>Add to Calendar</Styles.Header>
        {!isLiveEvent && (
          <Styles.Subtitle>
            Choose a time when you want your Workout Party to start.
          </Styles.Subtitle>
        )}
        <Styles.Content>
          <InputDateTime
            disabled={false}
            setValue={setStartTime}
            timeIntervals={15}
            useMinDate
            value={startTime}
          />
        </Styles.Content>
        <Styles.ScheduleButton onClick={() => onScheduleParty(startTime, event.duration)}>
          SCHEDULE A WORKOUT PARTY
        </Styles.ScheduleButton>
      </Styles.Body>
    </Styles.Dialog>
  )
}

const Styles = {
  Body: styled.div`
    padding: 0px 25px 40px 25px;
    user-select: none;
  `,
  Dialog: styled(Dialog.Main)`
    width: 612px;
    height: 544px;
    left: 0px;
    top: 0px;
    background: #ffffff;
    position: relative;
    overflow-x: hidden;
  `,
  Header: styled.h1`
    text-align: center;
    font-size: 46px;
    font-weight: 300;
  `,
  Dismiss: styled(Dialog.Header)`
    padding: 20px 10px;
  `,
  Subtitle: styled.h2`
    text-align: center;
    font-weight: 300;
    font-size: 15px;
    margin: 15px 0px 0px 0px;
  `,
  Content: styled.div`
    box-sizing: border-box;
    padding: 10px 0px 20px 0px;
    margin: 25px 0px 0px 0px;
    text-align: center;
  `,
  ScheduleButton: styled(SimpleButton)`
    background-color: var(--yellow);
  `,
  Party: styled(Icon.PartyButtonBlack)`
    position: absolute;
    top: 10px;
    left: 12px;
  `,
}
