import * as Sentry from '@sentry/nextjs'
import { Dialog } from 'src/components/Dialog'
import { Flex } from 'src/components/Display/Flex'
import { Icon } from 'src/components/Icon'
import SimpleButton from 'src/components/SimpleButton'
import { useUser } from 'src/hooks/useUser'
import { createParty } from 'src/models/classParty'
import moment from 'moment'

export const LaunchPartyModal = ({
  event,
  closeModal,
  isLiveEvent,
  isOpen,
  onStartSchedule,
  setIsOpen,
  eventStartTime,
  eventEndTime,
}) => {
  const { isMember } = useUser()

  const startTime = moment(eventStartTime)
  const endTime = moment(eventEndTime)
  const eventEnded = endTime.isBefore(moment())
  const livePartyAvailable = !eventEnded && startTime.isBefore(moment().add(1, 'hours'))

  const launchPartyBtnAvailable = !isLiveEvent ? true : livePartyAvailable
  const schedulePartyBtnAvailable = !isLiveEvent ? true : moment().isBefore(startTime)

  function onPartyClick() {
    const isLive = event?.id === undefined || event?.id === null

    const now = new Date()

    // live:  30 min before to at least 6 hours after, for class up to s1 hour ahead
    const pastDate = new Date()
    pastDate.setMinutes(now.getMinutes() - 30)

    const futureDate = new Date()
    futureDate.setHours(now.getHours() + 7)

    if (isMember) {
      /*
      console.log(
        'createParty',
        event.id,
        !isLive ? null : pastDate.toISOString(),
        !isLive ? null : futureDate.toISOString()
      )
      */
      createParty(
        event.id,
        !isLive ? null : pastDate.toISOString(),
        !isLive ? null : futureDate.toISOString(),
      )
        .then((data) => {
          const { id } = data

          window.location.href = `/party-detail/${id}`
        })
        .catch((error) => {
          setIsOpen(false)
          console.log(error)
          Sentry.captureException(error)
        })
    } else {
      window.location.reload()
      return
    }
  }

  return (
    <Styles.Dialog hideScrollbar isOpen={isOpen} onClose={closeModal}>
      <Styles.Dismiss noBorder onClose={closeModal} />
      <Styles.Body>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Styles.Beta />
        </div>
        <Styles.Header>Ain’t no party like an obé [workout] party</Styles.Header>
        <Styles.Subtitle>
          Our Workout Parties take the live obé experience and turn it into (even more of) a social
          event!
        </Styles.Subtitle>
        <Styles.Content>
          <Styles.Step>
            <Styles.StepNum>1</Styles.StepNum>
            <Styles.Text>Pick a class, and invite up to 7 of your besties to join you.</Styles.Text>
          </Styles.Step>
          <Styles.Step>
            <Styles.StepNum>2</Styles.StepNum>
            <Styles.Text>
              Via video chat and text chat, share every rep, every laugh, and every “that’s my jam!”
              moment.
            </Styles.Text>
          </Styles.Step>
          <Styles.Step>
            <Styles.StepNum>3</Styles.StepNum>
            <Styles.Text>
              It’s the easiest way to stay connected effortlessly with friends—to feel the love
              while you feel the burn.
            </Styles.Text>
          </Styles.Step>
          {launchPartyBtnAvailable && (
            <Styles.Button onClick={onPartyClick}>
              <Styles.Party />
              LAUNCH YOUR WORKOUT PARTY
            </Styles.Button>
          )}
          {schedulePartyBtnAvailable && (
            <Styles.ScheduleButton onClick={onStartSchedule}>
              SCHEDULE A WORKOUT PARTY
            </Styles.ScheduleButton>
          )}
        </Styles.Content>
      </Styles.Body>
    </Styles.Dialog>
  )
}

const Styles = {
  Body: styled.div`
    padding: 20px 25px;
    user-select: none;
  `,
  Beta: styled(Icon.Beta)`
    text-align: center;
    margin-bottom: 4px;
  `,
  Dialog: styled(Dialog.Main)`
    width: 612px;
    left: 0px;
    top: 0px;
    background: #ffffff;
    position: relative;
    overflow-x: hidden;
  `,
  Dismiss: styled(Dialog.Header)`
    padding: 10px;
  `,
  Header: styled.h1`
    text-align: center;
    font-size: 46px;
    font-weight: 300;
  `,
  Subtitle: styled.h2`
    text-align: center;
    font-weight: 300;
    font-size: 15px;
    margin: 15px 0px 0px 0px;
  `,
  Content: styled.div`
    box-sizing: border-box;
    border-top: 0.5px solid #d0d0d0;
    padding: 10px 0px 20px 0px;
    margin: 25px 0px 0px 0px;
  `,
  Step: styled(Flex.Row)`
    align-items: center;
    text-align: center;
    justify-content: center;
    margin: 0.9rem 0;
    padding: 0px 0px 5px 0px;
  `,
  StepNum: styled.div`
    height: 36px;
    width: 36px;
    border-radius: 18px;
    background-color: var(--persianBlue);
    color: #ffffff;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.2rem;
    padding: 8px 0px 0px 0px;
  `,
  Text: styled.div`
    text-align: left;
    text-transform: none;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.2rem;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 20px;
    width: 380px;
  `,
  ScheduleButton: styled(SimpleButton)`
    border: 1px solid var(--nearBlack);
    margin: 0 2rem;
  `,
  Button: styled(SimpleButton)`
    position: relative;
    background: var(--yellow);
    margin: 0 2rem 1rem;
  `,
  Party: styled(Icon.PartyButtonBlack)`
    margin-inline: 10px;
    display: inline;
  `,
}
