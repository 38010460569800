import { mutate } from 'swr'
import { addObeClassToCalendar, removeObeClassFromCalendar } from 'src/models/obeClass'
import { addToGoogleCalendar, buildiCalContent } from 'src/utils/calendarUtils'
import { breakpoint, button, mixin } from 'src/styles'
import { Dialog } from 'src/components/Dialog'
import { Flex } from 'src/components/Display/Flex'
import { InputDateTime } from 'src/components/InputDateTime'
import { isMobile } from 'react-device-detect'
import jstz from 'jstz'
import moment from 'moment'
import { saveAs } from 'file-saver'
import { useAsyncCallback } from 'react-async-hook'
import { UserAction } from 'src/components/UserAction'
import { useTrainingJourney } from 'src/hooks/useTrainingJourney'
import { checkIsProgramAutoSchedule } from './helpers'

const clearMyScheduleCache = () =>
  mutate(
    (key: string) => typeof key === 'string' && key.startsWith('/api/v4/members/my_schedule'),
    undefined,
    {
      revalidate: true,
    },
  )

export const AddToCalModal = ({
  isAdded,
  isOpen,
  reservationStartTime,
  setAdded,
  setIsOpen,
  setReservationStartTime,
  video,
  onCalendarChange = Function.prototype,
}) => {
  const initialTime = moment()
  const remainder = 15 - (initialTime.minute() % 15)
  const initialStartDateTimeVal = reservationStartTime
    ? moment(moment(reservationStartTime))
    : moment(initialTime).add(remainder, 'minutes').startOf('minute').toDate()
  const [startDateTimeVal, setStartDateTimeVal] = React.useState(initialStartDateTimeVal)
  const {
    category,
    classType,
    duration,
    id,
    instructorNames,
    instructors,
    title: videoTitle,
    isReplay,
  } = video

  const trainingJourney = useTrainingJourney()

  const title = `obé - ${videoTitle} with ${(
    instructorNames || instructors?.map(({ name }) => name)
  )?.join(' & ')}`
  const timezone = jstz.determine().name()
  const location = `https://www.obefitness.com/videos/${id}`
  const timeFormat = 'YYYYMMDDTHHmmss'
  const startDateTime = moment(startDateTimeVal).format(timeFormat)
  const endDateTime = moment(startDateTimeVal).add(duration, 'seconds').format(timeFormat)
  const events = isReplay ? obe.events.replay : obe.events.vod

  const tracking = !isAdded
    ? {
        classTitle: videoTitle || '',
        classCategory: classType?.name || category?.name || '',
        classType: isReplay ? 'replay' : 'on_demand',
        instructors: instructorNames?.join(', ') || '',
        startDate: moment(startDateTime).toISOString(),
        endDate: moment(endDateTime).toISOString(),
        videoId: video.id,
        trainingJourney,
      }
    : {}

  const onCalendarClick = useAsyncCallback(async () => {
    const isProgramAutoSchedule = checkIsProgramAutoSchedule(video)
    if (isAdded && !isProgramAutoSchedule) {
      setAdded(false)
      setReservationStartTime(undefined)
      await removeObeClassFromCalendar(video.id)
      await clearMyScheduleCache()
      onCalendarChange(video.id, false)
    } else {
      setAdded(true)
      setReservationStartTime(moment(startDateTime))
      await addObeClassToCalendar(video.id, undefined, moment(startDateTime).utc())
      obe.analytics.track(obe.events?.vod?.added_to_cal, tracking)
      await clearMyScheduleCache()
      onCalendarChange(video.id, true)
    }
  })

  const icsContent = buildiCalContent(
    document.URL,
    timezone,
    startDateTime,
    endDateTime,
    title,
    location,
  )

  const addToiCal = () => {
    if (isMobile) {
      // Can't save a blob w/ calendar type with mobile Safari
      window.open('data:text/calendar;charset=utf8,' + escape(icsContent))
    } else {
      downloadIcs()
    }
  }

  const downloadIcs = () => {
    const blob = new Blob([icsContent], { type: 'text/calendar' })
    return saveAs(blob)
  }

  return (
    <Styles.Dialog isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <Styles.DialogHeader onClose={() => setIsOpen(false)} />
      <Styles.Body>
        <Styles.Header>Add to calendar</Styles.Header>
        <Styles.Subtitle>{videoTitle}</Styles.Subtitle>
        <Flex.Row center style={{ paddingBottom: '1rem' }}>
          <Flex.Cell>
            <InputDateTime
              timeIntervals={15}
              value={startDateTimeVal}
              setValue={setStartDateTimeVal}
              popperPlacement='top-right'
              disabled={isAdded}
              useMinDate
            />
          </Flex.Cell>
        </Flex.Row>
        {!isReplay && (
          <Styles.Button
            isAdded={isAdded}
            isDisabled={
              onCalendarClick.loading || (!isAdded && moment(startDateTime).isBefore(moment()))
            }
            onClick={() => {
              if (isAdded || moment(startDateTime).isAfter(moment())) {
                onCalendarClick.execute()
              }
            }}
            disableTracking
          >
            {isAdded ? 'Remove from my obé Schedule' : 'Add to my obé Schedule'}
          </Styles.Button>
        )}
        <Styles.ButtonDivider />
        <Styles.SecondaryButton
          isDisabled={!startDateTimeVal}
          onClick={() => addToGoogleCalendar(startDateTime, endDateTime, timezone, location, title)}
          action={events?.added_to_cal}
          tracking={{ action: 'calendar_vod_add_google', ...tracking }}
        >
          Google Calendar
        </Styles.SecondaryButton>
        <Styles.SecondaryButton
          isDisabled={!startDateTimeVal}
          onClick={addToiCal}
          action={events?.added_to_cal}
          tracking={{ action: 'calendar_vod_add_ical', ...tracking }}
        >
          iCal
        </Styles.SecondaryButton>
        <Styles.SecondaryButton
          isDisabled={!startDateTimeVal}
          onClick={downloadIcs}
          action={events?.added_to_cal}
          tracking={{ action: 'calendar_vod_add_outlook', ...tracking }}
        >
          Outlook
        </Styles.SecondaryButton>
      </Styles.Body>
    </Styles.Dialog>
  )
}

const Styles = {
  Dialog: styled(Dialog.Main)`
    max-width: 540px;
  `,
  DialogHeader: styled(Dialog.Header)`
    border-bottom: 0px;
  `,
  Header: styled.h1`
    font-size: 32px;
    font-weight: 300;

    ${breakpoint.mediumAndUp} {
      font-size: 46px;
    }
  `,
  Subtitle: styled.h2`
    font-weight: 300;
    font-size: 20px;
    margin: 7px 0 15px 0;

    ${breakpoint.mediumAndUp} {
      margin: 25px 0 35px 0;
    }
  `,
  Button: styled(UserAction)`
    ${button.newPrimary}

    ${({ isAdded }) =>
      isAdded &&
      css`
        ${button.newSecondary}

        :focus {
          outline: 0;
        }
      `}
  `,
  SecondaryButton: styled(UserAction)`
    ${button.newSecondary}
    & + & {
      margin-top: 1rem;
    }
  `,
  Body: styled.div`
    padding: 0px 25px 35px 25px;
    text-align: center;

    ${breakpoint.mediumAndUp} {
      padding: 10px 83px 65px 83px;
    }
  `,
  ButtonDivider: styled.div`
    margin: 21px 0;
    width: 100%;
    border-bottom: 0.5px solid #d0d0d0;
  `,
  DateTimePicker: styled.div`
    ${mixin.flexSpaceEvenly}
  `,
}
