import DatePicker from 'react-datepicker'
import moment from 'moment'

import 'react-datepicker/dist/react-datepicker.css'

export const InputDateTime = ({
  format = 'dddd, MMMM Do, h:mm a',
  label = 'Date & Time',
  useMinDate,
  popperPlacement = 'auto',
  setValue,
  value,
  timeIntervals,
  disabled = false,
}) => {
  const calculateMinTime = (date) => {
    let isToday = moment(date).isSame(moment(), 'day')
    if (isToday) {
      return moment(new Date()).add({ minutes: 1 }).toDate()
    }
    return moment().startOf('day').toDate()
  }

  const handleDateChange = (date) => {
    setMinTime(calculateMinTime(date))
    setValue(date)
  }

  const [minTime, setMinTime] = React.useState(calculateMinTime(value || new Date()))

  return (
    <Styles.Container>
      {React.createElement(DatePicker, {
        customInput: <DateTimeInput format={format} label={label} disabled={disabled} />,
        onChange: handleDateChange,
        minDate: useMinDate ? moment().toDate() : null,
        maxDate: !useMinDate ? moment().toDate() : null,
        selected: moment(value).toDate(),
        showTimeSelect: true,
        timeIntervals,
        timeCaption: 'Time',
        dateFormat: 'MMMM d, yyyy h:mm aa',
        timeFormat: 'h:mm aa',
        minTime: minTime,
        maxTime: moment().endOf('day').toDate(), // set to 23:59 pm today
        popperPlacement,
        disabled,
        popperModifiers: {
          preventOverflow: {
            enabled: true,
          },
        },
      })}
    </Styles.Container>
  )
}

class DateTimeInput extends React.Component {
  render() {
    const { format, label, onClick, value, disabled } = this.props
    return (
      <Styles.Input onClick={onClick} isDisabled={disabled}>
        <Styles.Label>{label}</Styles.Label>
        <div>{moment(value).format(format)}</div>
      </Styles.Input>
    )
  }
}

const Styles = {
  Container: styled.div`
    .react-datepicker-wrapper {
      display: block;
    }

    .react-datepicker__navigation--next--with-time:not(
        .react-datepicker__navigation--next--with-today-button
      ) {
      right: 83px !important;
    }

    @media (max-width: 345px) {
      .react-datepicker__month-container {
        width: 230px;
      }

      .react-datepicker__month {
        margin: 0;
      }

      .react-datepicker__time-container {
        width: 70px;
      }

      .react-datepicker__time-box {
        width: 70px !important;
      }

      .react-datepicker__time-list-item {
        padding: 5px 0px !important;
      }
    }
  `,
  Input: styled.div`
    border: 2px #dbdbdb solid;
    cursor: pointer;
    padding: 0.25rem 0.5rem;
    width: 100%;

    ${({ isDisabled }) =>
      isDisabled &&
      css`
        cursor: unset;
        background-color: rgb(150, 150, 150, 0.1);
      `}
  `,
  Label: styled.label`
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0;
    text-transform: uppercase;
  `,
}
