import { ExportPartyModal } from './ExportPartyModal'
import { LaunchPartyModal } from './LaunchPartyModal'
import moment from 'moment'
import { ScheduleModal } from './ScheduleModal'
import { scheduleParty } from 'src/models/classParty'
import { useUser } from 'src/hooks/useUser'
import * as Sentry from '@sentry/nextjs'

export function LaunchPartyFlow({
  event,
  eventEndTime,
  eventStartTime,
  isLiveEvent,
  isOpen,
  setIsOpen,
  onScheduleParty = Function.prototype,
}) {
  const { isMember } = useUser()

  const [classPartyId, setClassPartyId] = React.useState(null)
  const [endTime, setEndTime] = React.useState(new Date())
  const [startTime, setStartTime] = React.useState(new Date())
  const [step, setStep] = React.useState('start')

  function closeFlow() {
    setIsOpen(false)
    setTimeout(() => setStep('start'), 500)
  }

  function onCompleteSchedule(startTime, endTime) {
    setStartTime(startTime)
    setEndTime(endTime)
    setStep('export')
  }

  async function schedulePartyCallback(startTimeDate, duration) {
    if (!isMember) {
      location.reload()
      return
    }
    const scheduleStartTime = startTimeDate

    const scheduleEndTime = moment(scheduleStartTime).add(duration, 'seconds').toDate()

    try {
      const party = await scheduleParty(
        event.id,
        scheduleStartTime.toISOString(),
        scheduleEndTime.toISOString(),
      )

      const { id } = party
      setClassPartyId(id)
      onScheduleParty(event.id, true)
    } catch (error) {
      closeFlow()
      console.log(error)
      Sentry.captureException(error)
    }

    onCompleteSchedule(scheduleStartTime, scheduleEndTime)
  }

  async function onStartSchedule() {
    if (isLiveEvent) {
      // LIVE: use the default time based on event and proceed
      // schedule the party instantly here, proceeding forward after

      const startTimeDate = moment(event.startTime).toDate()
      schedulePartyCallback(startTimeDate, event.duration * 60)
    } else {
      // VOD: first go to scheduler which will allow datetime selection,
      // it will then call schedulePartyCallback itself when done

      setStartTime(moment().add(1, 'hour').startOf('hour').toDate())
      setTimeout(() => setStep('schedule'), 500)
    }
  }

  switch (step) {
    case 'start':
      return (
        <LaunchPartyModal
          closeModal={closeFlow}
          event={event}
          eventEndTime={eventEndTime}
          eventStartTime={eventStartTime}
          isLiveEvent={isLiveEvent}
          isOpen={isOpen}
          onStartSchedule={onStartSchedule}
        />
      )
    case 'schedule':
      return (
        <ScheduleModal
          closeModal={closeFlow}
          event={event}
          isLiveEvent={isLiveEvent}
          isOpen={isOpen}
          onScheduleParty={schedulePartyCallback}
          setStartTime={setStartTime}
          startTime={startTime}
        />
      )
    case 'export':
      return (
        <ExportPartyModal
          classPartyId={classPartyId}
          closeModal={closeFlow}
          endTime={endTime}
          event={event}
          isLiveEvent={isLiveEvent}
          isOpen={isOpen}
          setIsOpen={closeFlow}
          startTime={startTime}
        />
      )
    default:
      return null
  }
}
