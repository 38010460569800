import styled from '@emotion/styled'

export default styled.div`
  border-radius: 1.5rem;
  cursor: pointer;
  height: 50px;
  padding: 13px 0px 0px 0px;
  text-align: center;
  text-transform: uppercase;
`
