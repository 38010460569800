import { Button } from 'src/components/Button/Button'
import { Calendar as CalendarIcon } from 'src/components/Display/Calendar'
import { useRouter } from 'src/hooks/useRouter'
import { useTrainingJourney } from 'src/hooks/useTrainingJourney'
import { useMediaQuery } from 'src/hooks/window'
import { breakpointMediaShort } from 'src/styles'
import { formatInstructorNames } from 'src/utils/instructorUtils'
import { AddToCalModal as AddVODToCalModal } from 'src/views/Video/VideoDetails/Calendar/AddToCalModal'
import { checkIsProgramAutoSchedule } from './helpers'

export const Calendar = ({
  video = {},
  isDisabled = false,
  onCalendarChange = Function.prototype,
  children = Function.prototype,
  isClassDetails = false,
}) => {
  const isLargeAndUp = useMediaQuery(breakpointMediaShort.largeAndUp)

  const [showModal, setShowModal] = React.useState(false)
  const [isAdded, setAdded] = React.useState(
    !checkIsProgramAutoSchedule(video) &&
      (video?.reservation?.addedToCalendar || video?.addedToCalendar || false),
  )
  const [reservationStartTime, setReservationStartTime] = React.useState(
    video?.reservation?.reservationStartTime,
  )

  const { asPath } = useRouter()
  const { title, instructors } = video

  const { id, duration } = video

  const trainingJourney = useTrainingJourney()

  const onOpenCalendarModal = () => {
    if (!isDisabled) {
      setShowModal(true)
      obe.analytics.track(obe.events.opened_calendar_modal, {
        duration: duration,
        instructors: formatInstructorNames(instructors),
        pageName: asPath,
        videoId: id,
        videoTitle: title,
        videoType: 'vod',
        trainingJourney,
      })
    }
  }

  return (
    <>
      <Button
        variant={isLargeAndUp ? 'secondary' : 'icon'}
        size='fit'
        className="group gap-2"
        onClick={onOpenCalendarModal}
        disabled={isDisabled}
      >
        {isClassDetails ? (
          <>
            <CalendarIcon
              isActive={isAdded}
              isDisabled={isDisabled}
              size={22}
              className="[&_path]:stroke-primary-400 [&_path]:group-hover:stroke-primary-500"
            />
            {isLargeAndUp && (
              <span className="hidden whitespace-nowrap text-nowrap lg:block">Add to schedule</span>
            )}
          </>
        ) : (
          <>
            <CalendarButtonIcon isActive={isAdded} isDisabled={isDisabled} />
            {children}
          </>
        )}
      </Button>
      <AddVODToCalModal
        video={video}
        isOpen={showModal}
        setIsOpen={setShowModal}
        isAdded={isAdded}
        setAdded={setAdded}
        reservationStartTime={reservationStartTime}
        setReservationStartTime={setReservationStartTime}
        onCalendarChange={onCalendarChange}
      />
    </>
  )
}

const CalendarButtonIcon = styled(CalendarIcon)`
  width: 27px;
`
