import { client } from 'src/api'

export const createParty = async (classId, startTime, endTime, params) => {
  const { data } = await client.post('/api/v4/class_parties', {
    ...params,
    obe_class_id: classId,
    start_time: startTime,
    end_time: endTime,
  })
  return data
}

export const scheduleParty = async (classId, startTime, endTime, params) => {
  const { data } = await client.post('/api/v4/class_parties/add_to_calendar', {
    ...params,
    obe_class_id: classId,
    start_time: startTime,
    end_time: endTime,
  })
  return data
}

export const getPartyStatus = async (partyId, params) => {
  const { data } = await client.get(`/api/v4/class_parties/${partyId}`, {
    params,
  })
  return data
}

export const joinParty = async (partyId, params) => {
  const { data } = await client.put(`/api/v4/class_parties/${partyId}/join_party`, params)
  return data
}

export const addClassToPartyPlaylist = async (partyId, classId, params) => {
  const { data } = await client.put(
    `/api/v4/class_parties/${partyId}/add_class?obe_class_id=${classId}`,
    params,
  )
  return data
}

export const removeClassFromPartyPlaylist = async (partyId, classId, params) => {
  const { data } = await client.delete(
    `/api/v4/class_parties/${partyId}/remove_class?obe_class_id=${classId}`,
    params,
  )
  return data
}
