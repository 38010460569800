import { addToGoogleCalendar, buildiCalContent } from 'src/utils/calendarUtils'
import { breakpoint, button } from 'src/styles'
import { Dialog } from 'src/components/Dialog'
import { isMobile } from 'react-device-detect'
import jstz from 'jstz'
import moment from 'moment'
import { saveAs } from 'file-saver'
import { UserAction } from 'src/components/UserAction'

export const ExportPartyModal = ({
  event,
  isOpen,
  setIsOpen,
  startTime,
  endTime,
  classPartyId,
  isLiveEvent,
}) => {
  const { category, classType, instructors, instructorNames, title: videoTitle } = event

  const instructorsNormalized = isLiveEvent
    ? (instructors.map((instructor) => instructor.name) || ['']).join(' & ')
    : (instructorNames || ['']).join(' & ')

  const title = `obé workout party - ${videoTitle} with ${instructorsNormalized}`
  const timezone = jstz.determine().name()
  const url = window.location.href
  const location = url.replace(window.location.pathname, `/party-detail/${classPartyId}/`)
  const timeFormat = 'YYYYMMDDTHHmmss'
  const startDateTime = moment(startTime).format(timeFormat)
  const endDateTime = moment(endTime).format(timeFormat)

  const icsContent = buildiCalContent(
    document.URL,
    timezone,
    startDateTime,
    endDateTime,
    title,
    location,
  )

  function addToiCal() {
    if (isMobile) {
      // Can't save a blob w/ calendar type with mobile Safari
      window.open('data:text/calendar;charset=utf8,' + escape(icsContent))
    } else {
      downloadIcs()
    }
  }

  function downloadIcs() {
    const blob = new Blob([icsContent], { type: 'text/calendar' })
    return saveAs(blob)
  }

  function onGoogleClick() {
    addToGoogleCalendar(startDateTime, endDateTime, timezone, location, title)
  }

  const tracking = {
    classTitle: videoTitle || '',
    classCategory: classType?.name || category?.name || '',
    classType: 'on_demand',
    instructors: instructorNames?.join(', ') || '',
    startDate: moment(startDateTime).toISOString(),
    endDate: moment(endDateTime).toISOString(),
  }

  return (
    <Styles.Dialog isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <Styles.DialogHeader onClose={() => setIsOpen(false)} />
      <Styles.Body>
        <Styles.Header>Add to calendar</Styles.Header>
        <Styles.Subtitle>{videoTitle}</Styles.Subtitle>
        <Styles.Success>WORKOUT PARTY CREATED!</Styles.Success>
        <Styles.Instructions>
          Next, add the event to your personal calendar and your invite friends
        </Styles.Instructions>
        <Styles.Time>
          <h3>Date & Time </h3>
          {moment(startTime).format('LLL')}
        </Styles.Time>
        <Styles.ButtonDivider />
        <Styles.SecondaryButton
          onClick={onGoogleClick}
          action={obe.events.vod.added_to_cal}
          tracking={{
            action: 'calendar_vod_add_google',
            videoId: event.id,
            ...tracking,
          }}
        >
          Google Calendar
        </Styles.SecondaryButton>
        <Styles.SecondaryButton
          onClick={addToiCal}
          action={obe.events.vod.added_to_cal}
          tracking={{
            action: 'calendar_vod_add_ical',
            videoId: event.id,
            ...tracking,
          }}
        >
          iCal
        </Styles.SecondaryButton>
        <Styles.SecondaryButton
          onClick={downloadIcs}
          action={obe.events.vod.added_to_cal}
          tracking={{
            action: 'calendar_vod_add_outlook',
            videoId: event.id,
            ...tracking,
          }}
        >
          Outlook
        </Styles.SecondaryButton>
      </Styles.Body>
    </Styles.Dialog>
  )
}

const Styles = {
  Dialog: styled(Dialog.Main)`
    max-width: 540px;
  `,
  DialogHeader: styled(Dialog.Header)`
    border-bottom: 0px;
  `,
  Header: styled.h1`
    font-size: 32px;
    font-weight: 300;

    ${breakpoint.mediumAndUp} {
      font-size: 46px;
    }
  `,
  Subtitle: styled.h2`
    font-weight: 300;
    font-size: 20px;
    margin: 7px 0 15px 0;

    ${breakpoint.mediumAndUp} {
      margin: 25px 0 35px 0;
    }
  `,
  Button: styled(UserAction)`
    ${button.primary}
    ${button.muted}
    background-color: #EAEAEA;
    border-color: #eaeaea;

    :focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
      outline: 0;
    }
  `,
  SecondaryButton: styled(UserAction)`
    ${button.secondary}
    & + & {
      margin-top: 1rem;
    }
  `,
  Body: styled.div`
    padding: 0px 25px 35px 25px;
    text-align: center;

    ${breakpoint.mediumAndUp} {
      padding: 10px 83px 65px 83px;
    }
  `,
  ButtonDivider: styled.div`
    margin: 21px 0;
    width: 100%;
    border-bottom: 0.5px solid #d0d0d0;
  `,
  Instructions: styled.div`
    margin-bottom: 1.5rem;
  `,
  Success: styled.div`
    color: var(--persianBlue);
    margin-bottom: 0.75rem;
  `,
  Time: styled.div`
    border: 1px solid var(--nearBlack);
    padding: 0.5rem;
    text-align: center;
  `,
}
