export function addToGoogleCalendar(startTime, endTime, timezone, location, title) {
  const baseUrl = 'https://calendar.google.com/calendar/render?action=TEMPLATE&'

  const detailsUrn = [
    `dates=${startTime}/${endTime}`,
    `ctz=${timezone}`,
    `location=${location}`,
    `text=${title}`,
    `details=${title}`,
  ]

  window.open(`${baseUrl}${detailsUrn.join('&')}`, '_blank')
}

export function buildiCalContent(url, timezone, startTime, endTime, title, location) {
  return [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'BEGIN:VEVENT',
    `URL:${url}`,
    'METHOD:PUBLISH',
    `DTSTART;TZID=${timezone}:${startTime}`,
    `DTEND;TZID=${timezone}:${endTime}`,
    `SUMMARY:${title}`,
    `DESCRIPTION:${title}`,
    `LOCATION:${location}`,
    'END:VEVENT',
    'END:VCALENDAR',
  ].join('\n')
}
